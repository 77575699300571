import Head from 'next/head'
import Script from 'next/script'

import { Tmx, TmxProfile } from 'components/Tmx'

import { SAC_INTERNATIONAL_PHONE } from 'settings/constants'

import { Banner } from 'containers/BannerContainer'
import { FormLogin } from 'containers/FormLoginContainer'
import { RecaptchaProvider } from 'providers/Recaptcha'
import * as Kernel from 'settings/kernel'
import * as SEO from 'settings/seo'

export default function Login() {
  return (
    <>
      <Head>
        <title>{SEO.SITE_TITLE}</title>
        <meta name='title' content={SEO.SITE_TAG_TITLE} />
        <meta name='description' content={SEO.SITE_TAG_DESCRIPTION} />
        {Tmx()}
      </Head>
      <Script
        type='application/ld+json'
        id='login-script'
        dangerouslySetInnerHTML={{
          __html: `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name" : "KaBuM!",
              "url": "${Kernel.KABUM_URL}",
              "logo": "${Kernel.STATIC_KABUM}/conteudo/temas/001/imagens/topo/logo_kabum_scrolldown.png",
              "contactPoint" : [{
                    "@type" : "ContactPoint",
                    "telephone" : ${SAC_INTERNATIONAL_PHONE},
                    "contactType" : "customer service"
              }],
              "sameAs" : [
                  "https://www.facebook.com/kabum.com.br/",
                  "https://www.instagram.com/kabum.com.br/",
                  "https://twitter.com/kabumcombr",
                  "https://www.linkedin.com/company/kabum-com-rcio-eletr-nico-s-a",
                  "https://www.youtube.com/user/kabumoficial"
              ]
            }`
        }}
      />

      <TmxProfile
        isBrowser={typeof window !== 'undefined'}
        orgId={Kernel.TMX_COMPANY_ID}
      />
      <RecaptchaProvider>
        <div className='flex w-full justify-center'>
          <div className='flex items-center justify-center flex-col px-12 desktop:grid desktop:grid-cols-2'>
            <div className='order-2'>
              <Banner />
            </div>
            <FormLogin />
          </div>
        </div>
      </RecaptchaProvider>
    </>
  )
}

export function getServerSideProps({ req, res }) {
  const clientId = req.cookies.id_cliente
  if (clientId) {
    return {
      redirect: {
        destination: '/',
        statusCode: 302
      },
      props: {}
    }
  }
  res.setHeader('Cache-Control', 'public, max-age=600')

  return {
    props: {}
  }
}
