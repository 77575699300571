import { useBanner } from './BannerContainer.hook'

export function Banner() {
  const { banner, bannerRef, dataLayerBannerClick, isLoading } = useBanner()

  if (banner) {
    return (
      <div className='flex w-full h-full px-16 mt-24 justify-center items-center'>
        <div className='desktop:block'>
          <a
            ref={bannerRef}
            href={banner?.destinationUrl}
            onClick={() => dataLayerBannerClick()}
            className={`flex items-center justify-center desktop:m-0 desktop:min-w-[592px] desktop:max-w-full desktop:h-[357px] ${isLoading && 'animate-pulse'}`}
          >
            <img
              src={banner?.mediaUrl}
              alt={banner?.alt}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </a>
        </div>
      </div>
    )
  }
}
